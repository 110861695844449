<template>
  <div class="box">
    <div class="step-bgc">
      <img src="../assets/img/pic_step_one.png" />
    </div>
    <div class="form-item">
      <div class="title">
        手机号码
      </div>
      <div>
        <van-field class="padding0" disabled v-model="mobile" clearable type="tel" placeholder="请填写手机号码"  />
      </div>
      <div class="border-line">
      </div>
    </div>
    <div class="form-item">
      <div class="title">
        现家庭住址（具体到门牌号，非学校和宿舍地址）
      </div>
      <div>
        <van-field class="padding0" v-model="docaddr" clearable placeholder="需提供地址以作备案"  />
      </div>
      <div class="border-line">
      </div>
    </div>
<!--    <div class="form-item">-->
<!--      <div class="title">-->
<!--        经常居住地-->
<!--      </div>-->
<!--      <div>-->
<!--        <van-field class="padding0" v-model="addr" clearable  placeholder="请填写经常居住地"  />-->
<!--      </div>-->
<!--      <div class="border-line">-->
<!--      </div>-->
<!--    </div>-->
    <div class="form-item">
      <div class="title">
        婚姻情况
      </div>
      <div>
        <van-field class="padding0" v-model="marriage" readonly right-icon="arrow" type="text" placeholder="请选择婚姻情况"  @click="marriageShow = true"/>
      </div>
      <div class="border-line">
      </div>
    </div>
    <div class="form-item">
      <div class="title">
        健康情况
      </div>
      <div>
        <van-field class="padding0" v-model="health" readonly right-icon="arrow" type="text" placeholder="请选择健康情况"  @click="healthShow = true"/>
      </div>
      <div class="border-line">
      </div>
    </div>
    <div class="form-item">
      <div class="title">
        是否有餐饮经验
      </div>
      <div>
        <van-field class="padding0" v-model="catering" readonly right-icon="arrow" type="text" placeholder="请确认是否有过往餐饮经验"  @click="cateringShow = true"/>
      </div>
      <div class="border-line">
      </div>
    </div>
    <div class="form-item">
      <div class="title">
        紧急联系人(家人姓名)
      </div>
      <div>
        <van-field class="padding0" v-model="emergencyContact" clearable placeholder="请填写紧急联系人姓名"  />
      </div>
      <div class="border-line">
      </div>
    </div>
    <div class="form-item">
      <div class="title">
        与紧急联系人关系
      </div>
      <div>
        <van-field class="padding0" readonly right-icon="arrow" v-model="jjlxrgxitem" clearable placeholder="请选择关系" @click="jjlxrgxShow = true" />
      </div>
      <div class="border-line">
      </div>
    </div>
    <div class="form-item">
      <div class="title">
        紧急联系人手机号码
      </div>
      <div>
        <van-field class="padding0" type="number" v-model="emergencyContactTel" clearable placeholder="请填写手机号码"  />
      </div>
      <div class="border-line">
      </div>
    </div>

    <van-action-sheet v-model="marriageShow" :actions="marriageList" @select="marriageSelect" cancel-text="取消"/>
    <van-action-sheet v-model="healthShow" :actions="healthList" @select="healthSelect" cancel-text="取消"/>
    <van-action-sheet v-model="cateringShow" :actions="cateringList" @select="cateringSelect" cancel-text="取消"/>
    <van-action-sheet v-model="jjlxrgxShow" :actions="jjlxrgxList" @select="jjlxrgxTypeSelect" cancel-text="取消"/>
  </div>
</template>

<script>
  import {Field,Picker,ActionSheet} from 'vant';
  export default {
    components: {
      [Field.name]: Field,
      [Picker.name]: Picker,
      [ActionSheet.name]: ActionSheet
    },
    data(){
      return {
        marriageList:[{name:'未婚',id:'0111'},{name:'已婚',id:'0112'},{name:'丧偶',id:'0113'},{name:'离婚',id:'0114'}],
        healthList:[{name:'健康',id:1},{name:'一般',id:2},{name:'有慢性病',id:3},{name:'有生理缺陷',id:4},{name:'残废',id:5},{name:'工伤',id:6},{name:'心血管病',id:7},{name:'脑血管病',id:8},{name:'慢性呼吸系统病',id:9},{name:'慢性消化系统病',id:10},{name:'慢性肾炎',id:11},{name:'结核病',id:12},{name:'糖尿病',id:13},{name:'神经或精神疾病',id:14},{name:'其他慢性病',id:15},{name:'聋哑',id:16},{name:'盲人',id:17},{name:'高度近视',id:18},{name:'其他缺陷',id:19},{name:'工伤',id:6},{name:'心血管病',id:7},{name:'脑血管病',id:8},{name:'慢性呼吸系统病',id:9},{name:'慢性消化系统病',id:10},{name:'慢性肾炎',id:11},{name:'特等残废',id:20},{name:'一等残废',id:21},{name:'二等甲级残废',id:22},{name:'二等乙级残废',id:23},{name:'三等甲级残废',id:24},{name:'三等乙级残废',id:25},{name:'其他残废',id:26},{name:'癌症',id:27}],
        cateringList:[{name:'有',id:1},{name:'无',id:2}],
        marriageShow: false,
        healthShow: false,
        cateringShow: false,
        jjlxrgxitem:'',
        jjlxrgxShow:false,
        jjlxrgxList:[{name:'父亲',id:1},{name:'母亲',id:2},{name:'夫妻',id:3},{name:'子女',id:4},{name:'爷爷',id:5},{name:'奶奶',id:6},{name:'其他亲属',id:7}],
        marriage:'',//婚姻
        health:'',//健康
        catering:'',//是否有餐饮经验
        emergencyContact:'',//紧急联系人
        emergencyContactTel:'',//紧急联系人电话
        mobile:'',//手机号
        // addr:'',//居住地
        docaddr:'',//联系方式
      }
    },
    mounted(){
      if (this.$route.query.mobile) {
        this.mobile = this.$route.query.mobile;
      }
    },
    methods:{
      marriageSelect(item) {
        this.marriage = item.name;
        this.marriageShow = false;
      },
      healthSelect(item) {
        this.health = item.name;
        this.healthShow = false;
      },
      cateringSelect(item) {
        this.catering = item.name;
        this.cateringShow = false;
      },
      jjlxrgxTypeSelect(item){
        this.jjlxrgxitem = item.name;
        this.jjlxrgxShow = false;
      },
      stepClcik(){
        let msg = this.validationRule();
        if (msg){
          return this.$toast.fail(msg);
        }
        let form = {
          mobile:this.mobile,
          // addr:this.addr,
          jjlxrgx:this.jjlxrgxitem,
          docaddr:this.docaddr,
          marital:this.marriage,
          healthy:this.health,
          cateringexp:this.catering,
          jjlxr:this.emergencyContact,
          jjlxrdh:this.emergencyContactTel
        }
        this.validEvent()
        sessionStorage.setItem('form',JSON.stringify(form));
      },
      validEvent(){
        this.$emit('vaildData',{
          status:2
        })
      },
      validationRule(){
        if (!this.mobile){
         return '请输入手机号码';
        }
        if (!this.docaddr){
          return '请输入联系方式';
        }
        // if (!this.addr){
        //   return '请输入经常居住地';
        // }
        if (!this.marriage){
          return '请选择婚姻状况';
        }
        if (!this.health){
          return '请选择是否健康';
        }
        if (!this.catering){
          return '请选择是否有餐饮经验';
        }
        if (!this.emergencyContact){
          return '请输入紧急联系人';
        }
        if (!this.emergencyContactTel){
          return '请输入紧急联系人手机号码';
        }
        if (this.emergencyContactTel.length !== 11 ){
          return '紧急联系人手机号码有误';
        }
        if (this.emergencyContactTel === this.mobile ){
          return '紧急联系人号码不能与员工手机号相同';
        }
      }
    }
  }
</script>
<style scoped>
  @import "../assets/style/index.css";
</style>
