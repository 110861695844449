import Vue from 'vue'
import VueRouter from 'vue-router'
import stepOne from '../views/stepOne.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/index.vue'),
    // meta:{
    //   keepAlive: true
    // }
  },
  {
    path: '/step-one',
    name: 'one',
    component: stepOne,
    // meta:{
    //   keepAlive: true
    // }
  },
  {
    path: '/step-two',
    name: 'two',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/stepTwo.vue'),
    // meta:{
    //   keepAlive: true
    // }
  },
  {
    path: '/step-three',
    name: 'three',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/stepThree.vue'),
    // meta:{
    //   keepAlive: true
    // }
  },
  {
    path: '/success',
    name: 'success',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/success.vue'),
    meta:{
      keepAlive: false
    }
  }
]

const router = new VueRouter({
  routes
})
export default router
